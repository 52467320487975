import React from "react";
import { VStack, HStack, Text, Pressable, Link as NLink } from "native-base";

import { vw, vh } from "../../util";
import "./one.css";
const bg = "https://cdn.propflip.io/propflip.io/hero/Web.webm";

//fold one large
const Large = ({ sz, landscape, sendIp }) => {
  //console.log("Fold 1 large Landscape", landscape);
  // console.log(vw(58),window.innerWidth)
  return (
    <VStack
      style={{
        height: "100vh",
      }}
    >
      <video
        src={bg}
        poster={
          "https://cdn.propflip.io/propflip.io.png/first-bg.webp"
        }
        loop
        autoPlay
        playsInline
        muted
        id="myVideo"
      >
        <source src={bg} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <VStack style={{ position: "absolute", width: "100%" }}>
        {/* <HStack
          space={2}
          style={{
            zIndex: 99,
            justifyContent: "space-between",
            paddingHorizontal: vw(214),
            paddingTop: vh(10),
          }}
        >
          <Center style={{ marginTop: vh(!landscape ? 20 : 10) }}>
            <WhiteLogo
              width={vw(!landscape ? 100 : 80)}
              height={vh(!landscape ? 140 : 120)}
            />
          </Center>
          <HStack space={vw(50)} style={{ marginTop: vh(68.94) }}>
            <NLink
              href="https://calendly.com/PropFlip/30min"
              isUnderlined={false}
            >
              <Text
                style={{
                  color: "white",
                  fontFamily: "Poppins_medium",
                  fontSize: vw(landscape ? 15 : 30),
                }}
              >
                Demo
              </Text>
            </NLink>
            {[2, 4, 5, 7].map((f) => (
              <Link
                key={"link-" + f}
                style={{
                  cursor: "pointer",
                  color: "white",
                  fontFamily: "Poppins_medium",
                  fontSize: vw(landscape ? 15 : 30),
                }}
                activeClass="active"
                to={"fold" + f}
                spy={true}
                smooth={true}
                offset={f === 8 ? -273 : 0}
                duration={1000}
                onClick={() => {
                  setFold(f);
                }}
                onSetActive={() => {}}
              >
                {f === 2 && "How it works"}
                {f === 4 && "Features"}
                {f === 5 && "Pricing"}
                {f === 7 && "Contact"}
              </Link>
            ))}
            <NLink href="/blog" isUnderlined={false}>
              <Text
                style={{
                  color: "white",
                  fontFamily: "Poppins_medium",
                  fontSize: vw(landscape ? 15 : 30),
                }}
              >
                Blog
              </Text>
            </NLink>
            <NLink
              href="https://web.shadow.properties/"
              isExternal
              isUnderlined={false}
            >
              <Text
                style={{
                  color: "white",
                  fontFamily: "Poppins_medium",
                  fontSize: vw(landscape ? 15 : 30),
                }}
              >
                Signup / Login
              </Text>
            </NLink>
          </HStack>
        </HStack> */}
        <Text
          style={{
            marginTop: landscape ? "20%" : "50%",
            marginLeft: vw(214),
            // width: vw(landscape ? 1500 : 800),
            // height: vh(272),
            lineHeight: vw(75),
            color: "white",
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: landscape ? vw(58) : vw(82),
            width: landscape ? "45%" : "55%",
            // backgroundColor:"gray"
          }}
        >
          Establish, Manage,{"\n"}and Grow as a {"\n"}Realtor, powered by <span style={{ color: 'rgb(125, 43, 233)'}}>AI.</span>
        </Text>
        <HStack
          style={{
            marginLeft: vw(214),
            marginTop: vw(39),
            justifyContent: "flex-start",
            alignItems: "center",
            height: vw(55),
            width: "50%",
          }}
        >
          <Pressable
            rounded={"full"}
            style={{
              borderWidth: landscape ? 2 : 3,
              borderColor: "white",
              width: vw(!landscape ? 320 : 180),
              height: vw(!landscape ? 100 : 50),
              alignItems: "center",
              justifyContent: "center",
            }}
            onPress={sendIp}
          >
            <Text
              style={{
                fontFamily: "poppins",
                color: "white",
                fontWeight: "400",
                fontSize: vw(!landscape ? 28 : 15),
                textAlign: "center",
              }}
            >
              Start Free Trial
            </Text>
          </Pressable>
          {/* <Circle
          _text={{
            fontSize: vw(30),
            color: "#7D2BE9",
          }}
          style={{
            backgroundColor: "rgba(255,255,255, 0.2)",
            marginLeft: vw(!landscape ? 50 : 60),
            width: vw(!landscape ? 100 : 60),
            height: vw(!landscape ? 100 : 60),
          }}
        >
          <Circle
            style={{
              backgroundColor: "white",
              width: vw(!landscape ? 80 : 50),
              height: vw(!landscape ? 80 : 50),
            }}
          >
            <PlayIcon size={vw(!landscape ? 50 : 30)} color={"#7D2BE9"} />
          </Circle>
        </Circle>
        <Pressable
          style={{
            height: vh(60),
            marginLeft: vw(20),
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "HelveticaNeueLTStd-Md",
              color: "white",
              fontSize: vw(!landscape ? 28 : 18),
              textAlign: "center",
            }}
          >
            Watch Video
          </Text>
        </Pressable> */}
        </HStack>
      </VStack>
    </VStack>
  );
};

export default React.memo(Large);
