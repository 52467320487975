import React from "react";
import {
  VStack,
  HStack,
  Text,
  Pressable,
  Link as NLink,
} from "native-base";
import { vw, vh } from "../../util";
import "./one.css";

//fold one medium
const Medium = ({ landscape, sendIp }) => {
  let platform = "web";
  if (navigator.userAgent.includes("iPhone")) {
    platform = "ios";
  } else if (navigator.userAgent.includes("Android")) {
    platform = "android";
  }
 const bg = landscape
   ? platform === "android"
     ? "https://cdn.propflip.io/propflip.io/hero/Web.webm"
     : "https://cdn.propflip.io/propflip.io/hero/Web.mp4"
   : platform === "android"
   ? "https://cdn.propflip.io/propflip.io/hero/mobile.webm"
   : "https://cdn.propflip.io/propflip.io/hero/mobile.mp4";
  return (
    <VStack
      style={{
        height: vh(1024),
        zIndex: 9,
      }}
    >
      <video
        src={bg}
        poster={
          landscape
            ? "https://cdn.propflip.io/propflip.io.png/first-bg.webp"
            : "https://cdn.propflip.io/propflip.io.png/hero-image-mobile.webp"
        }
        loop
        autoPlay
        playsInline
        muted
        id="myVideo"
      >
        <source src={bg} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <VStack>
        <Text
          style={{
            marginTop: vh(landscape ? 280 : 200),
            height: vh(272),
            lineHeight: vh(landscape ? 140 : 80),
            color: "white",
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: vw(landscape ? 65 : 90),
            textAlign: landscape ? "left" : "center",
            marginLeft: landscape ? vw(80) : 0,
            width:landscape?"60%":false
          }}
        >
          Establish, Manage, and Grow {"\n"}as a Realtor
        </Text>

        <HStack
          style={{
            marginTop: landscape ? vh(280) : vh(400),
            justifyContent: "center",
            alignItems: "center",
            height: vh(50),
            marginHorizontal: vw(51),
            marginVertical: vw(landscape ? 50 : 200),
          }}
        >
            <Pressable
              style={{
                borderRadius: 25,
                borderWidth: 2,
                borderColor: "white",
                width: vw(landscape ? 350 : 500),
                height: vw(landscape ? 70 : 100),
                alignItems: "center",
                justifyContent: "center",
                // boxShadow: "0px 0px 5px -1px",
              }}
              onPress={sendIp}
            >
              <Text
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  color: "white",
                  fontSize: vw(landscape ? 24 : 30),
                  textAlign: "center",
                  pt: 3,
                }}
              >
                Start Free Trial
              </Text>
            </Pressable>
          {/* <Circle
          _text={{
            fontSize: vw(30),
            color: "#7D2BE9",
          }}
          style={{
            backgroundColor: "rgba(255,255,255, 0.2)",
            marginLeft: vw(60),
            width: vw(landscape ? 80 : 100),
            height: vw(landscape ? 80 : 100),
          }}
        >
          <Circle
            style={{
              backgroundColor: "white",
              width: vw(landscape ? 70 : 80),
              height: vw(landscape ? 70 : 80),
            }}
          >
            <PlayIcon size={vw(50)} color={"#7D2BE9"} />
          </Circle>
        </Circle>
        <Pressable
          style={{
            height: vh(60),
            marginLeft: vw(20),
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "HelveticaNeueLTStd-Md",
              color: "white",
              fontSize: vw(landscape ? 24 : 30),
              textAlign: "center",
            }}
          >
            Watch Video
          </Text>
        </Pressable> */}
        </HStack>
      </VStack>
    </VStack>
  );
};

export default React.memo(Medium);
