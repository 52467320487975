import React, { useState, useRef, useEffect } from "react";
import FontFaceObserver from "fontfaceobserver";
import {
  VStack,
  Center,
  Text,
  HStack,
  Image,
  Pressable,
  Box,
} from "native-base";

import EyeIcon from "../../assets/svg/EyeIcon";
import SearchIcon from "../../assets/svg/SearchIcon";
import MoreIcon from "../../assets/svg/MoreIcon";

import { vh, vw } from "../../util";

const group1 = [
  {
    index: "1",
    heading: "Find & Add Properties",
    text: "Quickly find attractive properties based on the area code or address and drop a pin on the map to save them to your property list",
  },
  {
    index: "2",
    heading: "360° Property View",
    text: "This feature renders a 360° view of a property, enabling a virtually immersive and realistic experience—assess the property layout before scheduling a visit.",
  },
  {
    index: "3",
    heading: "Skip Trace",
    text: "Access contact information like email address and phone number of property owner using the Skip Trace feature and add additional contacts as needed.",
  },
  {
    index: "4",
    heading: "Choose the default address or email",
    text: "Set the provided address or add a new address as default to run campaigns successfully.",
  },
  {
    index: "5",
    heading: "Signatures and Templates",
    text: "Establish unique signatures and templates for each active campaign to build authority in the marketplace",
  },
];
const isAppleOrMac = /(Macintosh|iPad|iPhone|iPod)/i.test(navigator.userAgent);

const urls = isAppleOrMac
  ? [
      "https://cdn.propflip.io/propflip.io/how-it-works/Adding_properties.mp4",
      "https://cdn.propflip.io/propflip.io/how-it-works/MLS.mp4",
      "https://cdn.propflip.io/propflip.io/how-it-works/Smart_search.mp4",
      "https://cdn.propflip.io/propflip.io/how-it-works/Setting_as_default_1.mp4",
      "https://cdn.propflip.io/propflip.io/how-it-works/Temp_Sign_.mp4",
      "https://cdn.propflip.io/propflip.io/how-it-works/Campaign1.mp4",
      "https://cdn.propflip.io/propflip.io/how-it-works/list_management.mp4",
      "https://cdn.propflip.io/propflip.io/how-it-works/Pipeline_1.mp4",
      "https://cdn.propflip.io/propflip.io/how-it-works/Team_drive.mp4",
      "https://cdn.propflip.io/propflip.io/how-it-works/Buyers-Contact.mp4",
    ]
  : [
      "https://cdn.propflip.io/propflip.io/how-it-works/Adding_properties.webm",
      "https://cdn.propflip.io/propflip.io/how-it-works/MLS.webm",
      "https://cdn.propflip.io/propflip.io/how-it-works/Smart_search.webm",
      "https://cdn.propflip.io/propflip.io/how-it-works/Setting_as_default_1.webm",
      "https://cdn.propflip.io/propflip.io/how-it-works/Temp_Sign_.webm",
      "https://cdn.propflip.io/propflip.io/how-it-works/Campaign1.webm",
      "https://cdn.propflip.io/propflip.io/how-it-works/list_management.webm",
      "https://cdn.propflip.io/propflip.io/how-it-works/Pipeline_1.webm",
      "https://cdn.propflip.io/propflip.io/how-it-works/Team_drive.webm",
      "https://cdn.propflip.io/propflip.io/how-it-works/Buyers-Contact.webm",
    ];

const Large = ({ landscape, gifs, sz }) => {
  console.log("Fold 2 Large Landscape", landscape, "sz", sz);
  const [active, setActive] = useState(1);
  const [selected, setSelected] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const observer = new FontFaceObserver("Poppins");

    observer.load().then(() => {
      if (isLoaded !== true) setIsLoaded(true);
    });
  }, []);
  const videoRef = useRef(null);
  const bg = require("../../assets/png/Background.png");
  const Steps = React.memo((props) => {
    return (
      <Pressable
        onPress={() => {
          setSelected(props.index);
          videoRef.current.scrollIntoView({ block: "center" });
        }}
        onTouchStart={() => {
          setSelected(props.index);

          videoRef.current.scrollIntoView({ block: "center" });
        }}
      >
        <div className="box">
          <HStack
            key={props.index}
            style={{
              background:
                selected === props.index
                  ? "#FFFFFF4D 0% 0% no-repeat padding-box"
                  : "#F9F1FD 0% 0% no-repeat padding-box",
              // boxShadow:
              //   selected === props.index
              //     ? "15px 26px 50px #7D2BE959"
              //     : "15px 26px 50px transparent",
              // transition: "box-shadow 0.3s ease-in-out",
              border: "3px solid #FFFFFF",
              borderRadius: vw(20),
              minHeight: vw(100),
              padding: vw(20),
              // marginBottom: 35,
              // height: "min-content",
            }}
          >
            <Center
              style={{
                padding: vw(10),
                backgroundColor:
                  selected === props.index ? "#FFBB15" : "#8339FF",
                borderRadius: vw(8),
                width: vw(29),
                height: vw(29),
              }}
            >
              <Text color={"#FFFFFF"} fontFamily={"Poppins"} fontSize={vw(15)}>
                {props.index}
              </Text>
            </Center>
            <VStack width={"80%"}>
              <Text
                fontSize={landscape ? vw(18) : vw(24)}
                color={"#7D2BE9"}
                fontFamily={"Poppins"}
                fontWeight={600}
                marginLeft={vw(20)}
                marginBottom={vw(3)}
              >
                {props.heading}
              </Text>
              <Text
                // fontSize={vw(18)}
                // color={"#988AB4"}
                // fontFamily={"Poppins_medium"}
                marginLeft={vw(20)}
                fontSize={vw(15)}
                fontFamily="Poppins"
                fontWeight={300}
                color={"black"}
                lineHeight={vw(28)}
              >
                {props.children}
              </Text>
            </VStack>
          </HStack>
        </div>
      </Pressable>
    );
  });
  return (
    <VStack
      bg={"blue.200"}
      style={{
        padding: vw(32),
        background: `transparent url('${bg}') 0% 0% no-repeat padding-box`,
        backgroundSize: "contain",
        justifyContent: "center",
      }}
    >
      <HStack>
        <Box
          // height={vh(600)}
          // width={vw(900)}
          style={{
            marginRight: "auto",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width: landscape ? "60%" : sz === "lg" ? "60%" : "100%",
            aspectRatio: 1200 / 800,
          }}
        >
          <Box>
            <Text
              color={"#7D2BE9"}
              fontSize={vw(36)}
              ml={vw(100)}
              // mb={100}
              fontWeight={500}
              fontFamily="Poppins"
              lineHeight={vw(47)}
              maxW={vw(500)}
              mt={vw(60)}
            >
              Features Spotlight{"\n"}
            </Text>
            <Text
              fontSize={vw(15)}
              fontFamily="Poppins"
              fontWeight={300}
              color={"black"}
              maxW={vw(500)}
              ml={vw(100)}
              mt={"2%"}
              lineHeight={vw(28)}
            >
              A smart real estate mobile and web app designed for nationwide
              Property Finding, Investment Tracking, Marketing Automation, and
              Buyer Management with unparalleled features and performance.
            </Text>
          </Box>
        </Box>
        <Image
          alt="driving for dollars"
          style={{
            width: landscape ? "60%" : sz === "lg" ? "60%" : "100%",
            aspectRatio: 1200 / 800,
          }}
          source={require("../../assets/png/ShadowMobiles.png")}
          ml="auto"
        />
      </HStack>
      <HStack w={"100%"} style={{ justifyContent: "space-evenly" }}>
        <VStack w={"30%"} style={{ justifyContent: "space-evenly" }}>
          {group1.map((item) => {
            return (
              <Steps index={item.index} heading={item.heading}>
                {item.text}
              </Steps>
            );
          })}
        </VStack>
        <Center style={{ alignContent: "center" }}>
          {urls.map((item, index) => {
            return selected - 1 === index ? (
              <Center
                // key={"gif-" + idx}
                style={{
                  background: "#FFFFFF4D 0% 0% no-repeat padding-box",
                  boxShadow: "15px 26px 70px #00000026",
                  border: "6px solid #FFFFFF",
                  borderRadius: vw(35),
                  paddingVertical: window.innerWidth <= 900 ? vw(10) : vw(5),
                  // paddingHorizontal: window.innerWidth <= 1440 ? vh(10) : vh,
                  height: "min-content",
                  // marginTop: vh(100),
                }}
              >
                <video
                  style={{
                    width: vw(275),
                    // height: window.innerWidth <= 1440 ? vh(650) : vh(790),
                    backgroundColor: "transparent",
                    borderRadius: vw(30),
                    aspectRatio: 280 / 605,
                    margin: vw(6),
                  }}
                  src={item}
                  loop
                  autoPlay
                  playsInline
                  muted
                  preload="meta"
                  ref={videoRef}
                >
                  <source src={item} />
                </video>
              </Center>
            ) : (
              <></>
            );
          })}
        </Center>
        <VStack w={"30%"} style={{ justifyContent: "space-evenly" }}>
          <Steps index={6} heading={"Create a campaign"}>
            Create automated campaigns and send scheduled emails and postal
            mails to property owners directly from the app.
          </Steps>
          <Steps index={7} heading={"List management"}>
            Build custom property lists and attach a campaign that is
            automatically activated for each property within the list.
          </Steps>
          <Steps index={8} heading={"Pipeline"}>
            Organize your properties based on their deal status and swiftly move
            them from one pipeline to another.
          </Steps>
          <Steps index={9} heading={"Live Team Driving — An Unrivaled Feature"}>
            Easily monitor all the driving routes of your team members at once.
            You can also tap on individual members to follow their route.
          </Steps>
          <Steps index={10} heading={"Buyer contacts"}>
            Automate sharing shortlisted properties with interested buyers based
            on their requirements and close deals.
          </Steps>
        </VStack>
      </HStack>
      {/* fully featured mobile app */}
      <HStack>
        <Image
          alt="driving for dollars"
          style={{
            width: landscape ? "60%" : sz === "lg" ? "60%" : "100%",
            aspectRatio: 1600 / 1200,
          }}
          source={require("../../assets/png/fully_featured.png")}
          mr="auto"
        />
        <Box
          // height={vh(600)}
          // width={vw(900)}
          // backgroundColor="gray.200"
          style={{
            marginLeft: "auto",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            right: 0,
            width: landscape ? "60%" : sz === "lg" ? "60%" : "100%",
            aspectRatio: 1600 / 1200,
          }}
        >
          <Box>
            <Text
              color={"#7D2BE9"}
              fontSize={vw(36)}
              ml={vw(100)}
              // mb={100}
              fontWeight={500}
              fontFamily="Poppins"
              lineHeight={vw(47)}
              maxW={vw(500)}
              mt={vw(60)}
            >
              Fully Featured Mobile App{"\n"}
            </Text>
            <Text
              fontSize={vw(15)}
              fontFamily="Poppins"
              fontWeight={300}
              color={"black"}
              maxW={vw(500)}
              ml={vw(100)}
              mt={"2%"}
              lineHeight={vw(28)}
            >
              Don’t wait till you reach your office; close million-dollar deals
              from your couch using a full-fledged mobile app and stay
              lightyears ahead of your competition.
            </Text>
          </Box>
        </Box>
      </HStack>
      {/* Driving for Dollars */}
      <HStack>
        <Box
          // height={vh(600)}
          // width={vw(900)}
          style={{
            marginRight: "auto",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width: landscape ? "60%" : sz === "lg" ? "60%" : "100%",
            aspectRatio: 1600 / 1200,
          }}
        >
          <Box>
            <Text
              color={"#7D2BE9"}
              fontSize={vw(36)}
              ml={vw(100)}
              // mb={100}
              fontWeight={500}
              fontFamily="Poppins"
              lineHeight={vw(47)}
              maxW={vw(500)}
              mt={vw(60)}
            >
              Driving for Dollars{"\n"}
            </Text>
            <Text
              fontSize={vw(15)}
              fontFamily="Poppins"
              fontWeight={300}
              color={"black"}
              maxW={vw(500)}
              ml={vw(100)}
              mt={"2%"}
              lineHeight={vw(28)}
            >
              Let your team drive to the best properties nearby with the most
              advanced and accurate driving for dollars feature using our
              formidable mobile app. Monitor them all from one screen and
              provide instructions. Driving for dollars allows you to track
              every member from one screen and magnify them to follow individual
              directions.
            </Text>
          </Box>
        </Box>
        <Image
          alt="driving for dollars"
          style={{
            width: landscape ? "60%" : sz === "lg" ? "60%" : "100%",
            aspectRatio: 1600 / 1200,
          }}
          source={require("../../assets/png/Driving-for-dollars.png")}
          ml="auto"
        />
      </HStack>
      {/* MLS Properties */}
      <HStack>
        <Image
          alt="driving for dollars"
          style={{
            width: landscape ? "60%" : sz === "lg" ? "60%" : "100%",
            aspectRatio: 1600 / 1200,
          }}
          source={require("../../assets/png/MLS.png")}
          mr="auto"
        />
        <Box
          // height={vh(600)}
          // width={vw(900)}
          // backgroundColor="gray.200"
          style={{
            marginLeft: "auto",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            right: 0,
            width: landscape ? "60%" : sz === "lg" ? "60%" : "100%",
            aspectRatio: 1600 / 1200,
          }}
        >
          <Box>
            <Text
              color={"#7D2BE9"}
              fontSize={vw(36)}
              ml={vw(100)}
              // mb={100}
              fontWeight={500}
              fontFamily="Poppins"
              lineHeight={vw(47)}
              maxW={vw(500)}
              mt={vw(60)}
            >
              MLS Properties
            </Text>
            <Text
              fontSize={vw(15)}
              fontFamily="Poppins"
              fontWeight={300}
              color={"black"}
              maxW={vw(500)}
              ml={vw(100)}
              mt={"2%"}
              lineHeight={vw(28)}
            >
              Experience the world's top-of-the-line property management CRM and
              gain access to premium MLS properties with in-depth info to
              maximize your profits.
            </Text>
          </Box>
        </Box>
      </HStack>
      {/* Postcard and Email Automation */}
      <HStack>
        <Box
          // height={vh(600)}
          // width={vw(900)}
          style={{
            marginRight: "auto",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width: landscape ? "60%" : sz === "lg" ? "60%" : "100%",
            aspectRatio: 1600 / 1200,
          }}
        >
          <Box>
            <Text
              color={"#7D2BE9"}
              fontSize={vw(36)}
              ml={vw(100)}
              // mb={100}
              fontWeight={500}
              fontFamily="Poppins"
              lineHeight={vw(47)}
              maxW={vw(500)}
              mt={vw(60)}
            >
              Postcard and Email Automation{"\n"}
            </Text>
            <Text
              fontSize={vw(15)}
              fontFamily="Poppins"
              fontWeight={300}
              color={"black"}
              maxW={vw(500)}
              ml={vw(100)}
              mt={"2%"}
              lineHeight={vw(28)}
            >
              Effortlessly communicate with property owners with campaign
              automation feature & send periodic Postcards and Emails - close
              50,000 property deals a month with reusable templates.
            </Text>
          </Box>
        </Box>
        <Image
          alt="driving for dollars"
          // height={vh(800)}
          // width={vw(900)}
          style={{
            width: landscape ? "60%" : sz === "lg" ? "60%" : "100%",
            aspectRatio: 1600 / 1200,
          }}
          source={require("../../assets/png/market_auto.png")}
          ml="auto"
        />
      </HStack>
    </VStack>
  );
};

export default React.memo(Large);
