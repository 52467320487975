import React, { useState, useEffect } from "react";
import {
  VStack,
  HStack,
  Center,
  Text,
  Stack,
  Pressable,
  Divider,
  Link,
  Box,
  Spinner,
} from "native-base";
import { vw, vh } from "../../util";
import BottomBarSmall from "./templates/BottomBarSmall";
import IconSp from "../../assets/svg/Icon";
import TemplateMedium from "./templates/TemplateMedium";

import RightArrow from "../../assets/svg/RightArrow";
import LeftArrow from "../../assets/svg/LeftArrow";
import LeftArrowInactive from "../../assets/svg/LeftArrowInactive";
import RightArrowInactive from "../../assets/svg/RightArrowInactive";

const Small = ({
  perPage,
  totalPosts,
  page,
  setPage,
  isLoading,
  data,
  landscape,
  scrolled,
}) => {
  // const [isLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [filter, showFilter] = useState(false);
  const [pageCount, setPageCount] = useState(Math.ceil(totalPosts / perPage));
  // console.log(pageCount, totalPosts, "pageCount");
  useEffect(() => {
    setPageCount(Math.ceil(totalPosts / perPage));
  }, [totalPosts]);

  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   getData();
  //   console.log("ggggggggg");
  // }, []);

  // const getData = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://directproads.com/wp-json/wp/v2/media"
  //     );
  //     const json = await response.json();
  //     setData(json);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // const [scrollPosition, setScrollPosition] = useState(0);
  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: false });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  const styles = {
    container: {
      height: "32px",
      width: "32px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "4px",
    },
    line: {
      height: "2px",
      width: "20px",
      background: "black",
      transition: "all 0.2s ease",
    },
    lineTop: {
      transform: isOpen ? "translateX(-2px) rotate(-45deg)" : "none",
      transformOrigin: "top right",
      marginBottom: "5px",
    },
    lineMiddle: {
      opacity: isOpen ? 0 : 1,
      transform: isOpen ? "translateX(16px)" : "none",
    },
    lineBottom: {
      transform: isOpen ? "rotate(45deg)" : "none",
      transformOrigin: "top right",
      marginTop: "5px",
    },
  };
  const menuList = [
    { name: "Home", route: "#main" },
    { name: "How it works", route: "#howitworks" },
    { name: "Features", route: "#features" },
    { name: "Pricing", route: "#pricing" },
    { name: "Contact", route: "#contact" },
    { name: "Blog", route: "blog" },
    {
      name: "Demo",
      route: "https://calendly.com/shadow-demo/30min?month=2023-06",
    },
    {
      name: "Signup/Login",
      route: "https://web.shadow.properties/",
    },
  ];
  const MenuItem = (props) => {
    return (
      <VStack>
        {menuList.map((item) => {
          return (
            <Center h={landscape ? vh(110) : vh(60)}>
              <Link
                href={
                  item.name === "Demo" || item.name === "Signup/Login"
                    ? item.route
                    : "/" + item.route
                }
              >
                <Text fontFamily={"Poppins"} fontWeight={"bold"}>
                  {item.name}
                </Text>
              </Link>
            </Center>
          );
        })}
      </VStack>
    );
  };
  return (
    <>
      <VStack>
        {/* <div className={filter ? "side-drawer open" : "side-drawer"}>
          <VStack style={{ width: "100%", height: "100%", padding: 20 }}>
            <Pressable
              onPress={() => {
                showFilter(!filter);
              }}
            >
              <Text
                fontFamily={"poppins"}
                color={"#777"}
                fontSize={11}
              >
                X Close
              </Text>
            </Pressable>
            <Divider my={3} />
          </VStack>
        </div>
        <div
          onClick={() => {
            showFilter(!filter);
          }}
          className={filter ? "backdrop" : ""}
        ></div>
        <Box
          style={{
            position: "fixed",
            width: "100%",
            // height: "100%",
            zIndex: 999,
            boxShadow: scrolled ? "1px 1px 8px #00000020" : "1px 1px 2px #eee",
          }}
        >
          <VStack
            bg="white"
            w={"full"}
            borderBottomWidth={0.001}
            borderBottomColor={"gray.200"}
            h={landscape ? vh(150) : vh(85)}
            zIndex={100}
          >
            <HStack
              mt={landscape ? vh(30) : vh(20)}
              ml={landscape ? vw(90) : vw(140)}
              mr={landscape ? vw(90) : vw(140)}
              justifyContent={"space-between"}
            >
              <Stack>
                <Link href="https://propflip.io">
                  <IconSp
                    width={landscape ? vw(100) : vw(100)}
                    height={landscape ? vh(80) : vh(40)}
                  />
                </Link>
              </Stack>
              <Stack>
                <div
                  style={styles.container}
                  onClick={() => {
                    setOpen(!isOpen);
                  }}
                >
                  <div style={{ ...styles.line, ...styles.lineTop }} />
                  <div style={{ ...styles.line, ...styles.lineMiddle }} />
                  <div style={{ ...styles.line, ...styles.lineBottom }} />
                </div>
              </Stack>
            </HStack>
          </VStack>
          <VStack
            style={{
              // display: isOpen ? "flex" : "none",
              position: "absolute",
              zIndex: 99,
              width: "100%",
              height: landscape ? "100vh" : "60vh",
              // paddingTop: vw(300),
              backgroundColor: "white",
              transform: isOpen ? "translateY(0%)" : "translateY(-100%)",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <VStack
              justifyContent={"center"}
              alignItems={"center"}
              w={"100%"}
              h={isOpen ? "120%" : "100%"}
              backgroundColor={"white"}
              transition="all 0.3s linear"
            >
              <MenuItem isOpen={isOpen} />
            </VStack>
            {isOpen && (
              <div
                style={{
                  backgroundColor: "#000",
                  opacity: "0.5",
                  minHeight: "110vh",
                  minWidth: "100%",
                }}
                onClick={() => {
                  setOpen(!isOpen);
                }}
              >
                {" "}
              </div>
            )}
          </VStack>
        </Box> */}
        {/* filter button */}
        {/* <Center mt={landscape ? vh(230) : vh(100)}>
          <Pressable
            onPress={() => {
              showFilter(!filter);
            }}
          >
            <HStack alignContent={"flex-start"}>
              <Stack mr={2} mt={landscape ? vh(15) : vh(5)}>
                <ShowFilter
                  height={landscape ? vw(25) : vh(18)}
                  width={landscape ? vw(60) : vw(50)}
                />
              </Stack>
              <Stack>
                <Text
                  fontSize={landscape ? vw(35) : vh(18)}
                  fontFamily={"Poppins_medium"}
                >
                  Show filters
                </Text>
                <Text>{filter}</Text>
              </Stack>
            </HStack>
          </Pressable>
        </Center> */}
        <Stack>
          {isLoading ? (
            <Center minHeight={"100vh"}>
              {" "}
              <Spinner size={"sm"} color={"black"} />
            </Center>
          ) : (
            <Center>
              {/* <FlatList
                padding={10}
                // paddingTop={-10}
                numColumns={landscape ? 2 : 1}
                data={data}
                keyExtractor={({ id }, index) => id}
                renderItem={({ item }) => (
                  <Center mt={landscape ? vh(120) : vh(70)}>
                    <TemplateMedium
                      Orientation={landscape}
                      width={vw(1300)}
                      height={vh(700)}
                      item={item}
                      image={
                        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                      }
                    />
                  </Center>
                )}
              /> */}
              <HStack
                flexWrap={"wrap"}
                justifyContent={"center"}
                mt={landscape ? vh(120) : vh(70)}
              >
                {data.map((item) => (
                  <Center mt={landscape ? vh(120) : vh(70)}>
                    <TemplateMedium
                      Orientation={landscape}
                      width={vw(1300)}
                      height={vh(700)}
                      item={item}
                      image={
                        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                      }
                    />
                  </Center>
                ))}
              </HStack>
              <HStack
                width={"100%"}
                justifyContent={"space-around"}
                alignItems={"center"}
                mt={"10"}
              >
                {page !== 1 && (
                  <Pressable
                    onPress={() => {
                      window.scrollTo({ top: 0 });
                      setPage(page - 1);
                    }}
                  >
                    <Box>
                      <LeftArrow />
                    </Box>
                  </Pressable>
                )}
                {page === 1 && (
                  <Box>
                    <LeftArrowInactive />
                  </Box>
                )}
                <Text fontFamily={"poppins"} fontSize={"110%"}>
                  Page: {page}
                </Text>
                {page !== pageCount && (
                  <Pressable
                    onPress={() => {
                      window.scrollTo({ top: 0 });
                      setPage(page + 1);
                    }}
                  >
                    <Box>
                      <RightArrow />
                    </Box>
                  </Pressable>
                )}
                {page === pageCount && (
                  <Box>
                    <RightArrowInactive />
                  </Box>
                )}
              </HStack>
            </Center>
          )}
        </Stack>

        {/* <VStack bg="black" w={"full"} h={vh(990)}>
        <Center>
          <Text color={"white"}>Contact the publisher</Text>
        </Center>

      </VStack> */}
        <VStack mt={vh(120)}>
          <BottomBarSmall Orientation={landscape} />
        </VStack>
      </VStack>
    </>
  );
};

export default React.memo(Small);
