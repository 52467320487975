import React, { useEffect } from "react";
import { Link, Spinner } from "native-base";

const RedirectPage = ({ sendIp }) => {
  let platform = "W";
  if (navigator.userAgent.includes("iPhone")) {
    platform = "I";
  } else if (navigator.userAgent.includes("Android")) {
    platform = "A";
  }
  const ip = sendIp;
  const redirect = () => {
    if (platform === "I") {
      window.location.replace(
        "https://apps.apple.com/us/app/PropFlip/id1491176685/"
      );
      window.sessionStorage.clear("os");
    }
    if (platform === "A") {
      window.location.replace(
        "https://play.google.com/store/apps/details?id=and.app.properties.shadow"
      );
      window.sessionStorage.clear("os");
    }
    if (platform === "W") {
      window.location.replace("https://web.shadow.properties/");
      window.sessionStorage.clear("os");
    }
  };
  useEffect(() => {
    (async () => {
      const search = window.location.search;
      if (search) {
        const params = search.split("&");
        const sub1 = params[0].split("=")[1];
        const sub2 = params[1].split("=")[1];
        const os = platform;
        const ua = navigator.userAgent;
        // console.log(ua,"ua")
        if (os !== null && ip !== null) {
          await post("https://api.shadow.properties/v2/setip", {
            sub1,
            sub2,
            ip,
            os,
            ua,
          });
          // const localItem=JSON.stringify({sub1,sub2,ip,os,ua})
          // localStorage.setItem("SPUID",localItem);
          // console.log(localStorage.getItem("SPUID"), "localstorage item");
          if (platform === "I") {
            window.location.replace(
              "https://apps.apple.com/us/app/PropFlip/id1491176685/"
            );
            window.sessionStorage.clear("os");
          }
          if (platform === "A") {
            window.location.replace(
              "https://play.google.com/store/apps/details?id=and.app.properties.shadow"
            );
            window.sessionStorage.clear("os");
          }
          if (platform === "W") {
            window.location.replace("https://web.shadow.properties/");
            window.sessionStorage.clear("os");
          }
          // console.log("sub1", sub1, "sub2", sub2);
        }
      }
    })();
  }, [ip]);
  const post = async (url, data) => {
    try {
      const URL = url;
      console.log("data", data, "url", url);
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),

        redirect: "follow",
      });

      if (response.ok) {
        const details = await response.json();
        console.log(details);
        // window.localStorage.setItem("SPUID", JSON.stringify(data));
        // console.log(window.localStorage.getItem("SPUID"), "localstorage item");
        // alert(window.localStorage.getItem("SPUID"));
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <h1 style={{ fontSize: window.innerWidth < 720 ? "12px" : "18px" }}>
        Redirecting, Please wait...
      </h1>
      <Spinner
        size={25}
        color={"black"}
        accessibilityLabel="Redirecting page"
      />
      <p
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: window.innerWidth < 720 ? "10px" : "12px",
        }}
      >
        Taking too long?{" "}
        <Link onPress={redirect}>
          <div style={{ color: "blue" }}>Click here</div>
        </Link>
      </p>
    </div>
  );
};
export default RedirectPage;
