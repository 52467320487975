import {
  Box,
  Center,
  Image,
  Text,
  VStack,
  useBreakpointValue,
  useMediaQuery,
  HStack,
  Stack,
  Link,
  Spinner,
} from "native-base";
import React, { useState, useEffect } from "react";
import BottomBar from "../templates/BottomBar";
import { vw, vh } from "../../../util";
import { Helmet } from "react-helmet";
import IconSp from "../../../assets/svg/Icon";
import BottomBarSmall from "../templates/BottomBarSmall";
import WhiteLogo from "../../../assets/svg/WhiteLogo";
import PostHeader from "./PostHeader";
import { useParams } from "react-router-dom";

function PostPage() {
  const { post } = useParams();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [filter, showFilter] = useState(false);
  const [data, setData] = useState([]);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
      const isScrolledUp = window.scrollY < 10;
      if (isScrolledUp !== scrolled) {
        setScrolled(!isScrolledUp);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);
  console.log(
    data[0]?._embedded["wp:featuredmedia"][0]?.source_url,
    "data at post page"
  );
  useEffect(() => {
    sub();
  }, []);
  const sub = async () => {
    try {
      setIsloading(true);

      const url = `https://apiblog.shadow.properties/wp-json/wp/v2/posts?slug=${post}&_embed`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },

        redirect: "follow",
      });

      if (response.ok) {
        const details = await response.json();

        details
          .sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
          })
          .reverse();
        setData(details);
        console.log(details, "all data");
        setIsloading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const sz = useBreakpointValue({
    base: "base",
    lg: "lg",
    xl: "xl",
    md: "md",
    sm: "sm",
  });
  const [landscape] = useMediaQuery([
    {
      orientation: "landscape",
    },
    {
      orientation: "portrait",
    },
  ]);
  const styles = {
    container: {
      height: "32px",
      width: "32px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "4px",
    },
    line: {
      height: "2px",
      width: "20px",
      background: scrolled || isOpen ? "black" : "white",
      transition: "all 0.2s ease",
    },
    lineTop: {
      transform: isOpen ? "translateX(-2px) rotate(-45deg)" : "none",
      transformOrigin: "top right",
      marginBottom: "5px",
    },
    lineMiddle: {
      opacity: isOpen ? 0 : 1,
      transform: isOpen ? "translateX(16px)" : "none",
    },
    lineBottom: {
      transform: isOpen ? "rotate(45deg)" : "none",
      transformOrigin: "top right",
      marginTop: "5px",
    },
  };
  const menuList = [
    { name: "Home", route: "#main" },
    { name: "How it works", route: "#howitworks" },
    { name: "Features", route: "#features" },
    { name: "Pricing", route: "#pricing" },
    { name: "Contact us", route: "#contact" },
    { name: "Blog", route: "blog" },
    {
      name: "Demo",
      route: "https://calendly.com/shadow-demo/30min?month=2023-06",
    },
    {
      name: "Signup/Login",
      route: "https://web.shadow.properties/",
    },
  ];
  const MenuItem = () => {
    return (
      <VStack>
        {menuList.map((item) => {
          return (
            <Center h={vh(60)}>
              <Link href={item.name === "Demo" ? item.route : "/" + item.route}>
                <Text fontFamily={"Poppins"} fontWeight={"bold"}>
                  {item.name}
                </Text>
              </Link>
            </Center>
          );
        })}
      </VStack>
    );
  };
  return !isLoading ? (
    <VStack>
      <Helmet>
        <title>Real Estate CRM | PropFlip</title>
      </Helmet>
      {(sz === "base" || sz === "sm" || sz === "md") && (
        <Box
          style={{
            position: "fixed",
            width: "100%",
            zIndex: 1000,
            boxShadow: scrolled ? "1px 1px 8px #00000020" : "",
            backgroundColor: scrolled ? "white" : "transparent",
            transition: "background-color 0.3s ease-in-out",
          }}
        >
          <VStack
            // bg="white"
            w={"full"}
            h={landscape ? vh(150) : vh(85)}
            zIndex={100}
          >
            <HStack
              mt={landscape ? vh(30) : vh(20)}
              ml={landscape ? vw(90) : vw(140)}
              mr={landscape ? vw(90) : vw(140)}
              justifyContent={"space-between"}
            >
              <Stack>
                {scrolled || isOpen ? (
                  <Link href="/">
                    <IconSp
                      width={landscape ? vw(100) : vw(100)}
                      height={landscape ? vh(80) : vh(40)}
                    />
                  </Link>
                ) : (
                  <Link href="/">
                    <WhiteLogo
                      width={landscape ? vw(100) : vw(100)}
                      height={landscape ? vh(80) : vh(40)}
                    />
                  </Link>
                )}
              </Stack>
              <Stack>
                {/* <Menu
                width={landscape ? vw(100) : vw(100)}
                height={landscape ? vh(60) : vh(40)}
              /> */}
                <div
                  style={styles.container}
                  onClick={() => {
                    setOpen(!isOpen);
                  }}
                >
                  <div style={{ ...styles.line, ...styles.lineTop }} />
                  <div style={{ ...styles.line, ...styles.lineMiddle }} />
                  <div style={{ ...styles.line, ...styles.lineBottom }} />
                </div>
              </Stack>
            </HStack>
          </VStack>
          <Box
            style={{
              position: "fixed",
              width: "100%",
              // height: "100%",
              zIndex: 999,
              boxShadow: scrolled ? "1px 1px 8px #00000020" : "",
            }}
          >
            <VStack w={"full"} h={landscape ? vh(150) : vh(85)} zIndex={100}>
              <HStack
                mt={landscape ? vh(30) : vh(20)}
                ml={landscape ? vw(90) : vw(140)}
                mr={landscape ? vw(90) : vw(140)}
                justifyContent={"space-between"}
              >
                <Stack>
                  {scrolled || isOpen ? (
                    <Link href="/">
                      <IconSp
                        width={landscape ? vw(100) : vw(100)}
                        height={landscape ? vh(80) : vh(40)}
                      />
                    </Link>
                  ) : (
                    <Link href="/">
                      <WhiteLogo
                        width={landscape ? vw(100) : vw(100)}
                        height={landscape ? vh(80) : vh(40)}
                      />
                    </Link>
                  )}
                </Stack>
                <Stack>
                  <div
                    style={styles.container}
                    onClick={() => {
                      setOpen(!isOpen);
                    }}
                  >
                    <div style={{ ...styles.line, ...styles.lineTop }} />
                    <div style={{ ...styles.line, ...styles.lineMiddle }} />
                    <div style={{ ...styles.line, ...styles.lineBottom }} />
                  </div>
                </Stack>
              </HStack>
            </VStack>
            <VStack
              style={{
                // display: isOpen ? "flex" : "none",
                position: "absolute",
                zIndex: 99,
                width: "100%",
                height: "100vh",
                // paddingTop: vw(300),
                backgroundColor: "white",
                transform: isOpen ? "translateY(0%)" : "translateY(-100%)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <VStack
                justifyContent={"center"}
                alignItems={"center"}
                w={"100%"}
                h={isOpen ? "110%" : "100%"}
                backgroundColor={"white"}
                transition="all 0.3s linear"
              >
                <MenuItem isOpen={isOpen} />
              </VStack>
            </VStack>
          </Box>
        </Box>
      )}
      {(sz === "lg" || sz === "xl") && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 9999,
            backgroundColor: scrolled ? "#fff" : "transparent",
            boxShadow: scrolled ? "1px 1px 8px #00000020" : "",
            transition: "background-color 0.3s ease-in-out",
          }}
        >
          <PostHeader
            isOpen={isOpen}
            landscape={landscape}
            scrolled={scrolled}
          />
        </div>
      )}
      <Center
        justifyContent={"flex-start"}
        w={"100%"}
        height={"min-content"}
        backgroundColor={"white"}
        style={{
          zIndex: 999,
          bottom: sz === "base" || sz === "sm" || sz === "md" ? vh(0) : vh(600),
          marginTop:
            sz === "base" || sz === "sm" || sz === "md" ? vh(0) : vh(600),
        }}
      >
        {/* <div>
          <img
            src={img}
            alt="no image"
            style={{ height: "100vh", width: "100vw" }}
          />
        </div> */}
        <Box width={"100%"} height={"100%"}>
          <Image
            style={{ filter: "brightness(60%)" }}
            height={"100vh"}
            width={"full"}
            source={{
              uri: data[0]?._embedded["wp:featuredmedia"][0]?.source_url,
            }}
          />
          <Box width={"100%"} height={"100%"} position={"absolute"}>
            <VStack
              width={"100%"}
              height={"100%"}
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <h
                className={landscape ? "blog-heading" : "blog-heading-mobile"}
                dangerouslySetInnerHTML={{
                  __html: data[0]?.title?.rendered,
                }}
              ></h>
            </VStack>
          </Box>
        </Box>
        <VStack w={"100%"}>
          <div style={{ zIndex: 999 }}>
            <div
              className="content-container"
              dangerouslySetInnerHTML={{
                __html: data[0]?.content?.rendered,
              }}
            />
          </div>
        </VStack>
      </Center>
      {(sz === "base" || sz === "sm" || sz === "md") && (
        <BottomBarSmall Orientation={landscape} />
      )}
      <VStack
        mt={"auto"}
        style={{
          position: "fixed",
          bottom: 0,
          zIndex: 998,
          backgroundColor: "black",
        }}
      >
        <VStack>{(sz === "lg" || sz === "xl") && <BottomBar />}</VStack>
      </VStack>
      {/* <Pressable
        onPress={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <Circle
          style={{
            boxShadow: "0 0 30px rgb(0 0 0 / 10%)",
            backgroundColor: scrollPosition > 300 ? "white" : "transparent",
            display: scrollPosition > 300 ? "flex" : "none",
          }}
          size={50}
          position={"fixed"}
          bottom={10}
          right={10}
          zIndex={9999}
        >
          <UpperArrow width={30} height={30} />
        </Circle>
      </Pressable> */}
    </VStack>
  ) : (
    // <div class="center">
    //   <div class="wave"></div>
    //   <div class="wave"></div>
    //   <div class="wave"></div>
    //   <div class="wave"></div>
    //   <div class="wave"></div>
    //   <div class="wave"></div>
    //   <div class="wave"></div>
    //   <div class="wave"></div>
    //   <div class="wave"></div>
    //   <div class="wave"></div>
    // </div>
    <Center style={{ height: "100vh" }}>
      <Spinner size={"lg"} color={"black"} />
    </Center>
  );
}

export default React.memo(PostPage);
