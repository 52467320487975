import React, { useState, useEffect } from "react";
import { useBreakpointValue, useMediaQuery } from "native-base";
import { Helmet } from "react-helmet";
import Medium from "./Medium";
import Small from "./Small";
import Large from "./Large";

const BlogFold = ({
  perPage,
  totalPosts,
  page,
  setPage,
  setCount,
  isLoading,
  data,
}) => {
  const sz = useBreakpointValue({
    base: "base",
    lg: "lg",
    xl: "xl",
    md: "md",
    sm: "sm",
  });
  const [landscape] = useMediaQuery([
    {
      orientation: "landscape",
    },
    {
      orientation: "portrait",
    },
  ]);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
      const isScrolledUp = window.scrollY < 10;
      if (isScrolledUp !== scrolled) {
        setScrolled(!isScrolledUp);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);
  // console.log(totalPosts, "total posts");
  return (
    <>
      <Helmet>
        <title>Real Estate CRM | PropFlip</title>
      </Helmet>
      {/* for small devices */}
      {(sz === "base" || sz === "sm") && (
        <Small
          perPage={perPage}
          totalPosts={totalPosts}
          page={page}
          data={data}
          setCount={setCount}
          setPage={setPage}
          isLoading={isLoading}
          landscape={landscape}
          sz={sz}
          scrolled={scrolled}
        />
      )}
      {/* for medium devices */}
      {sz === "md" && (
        <Medium
          perPage={perPage}
          totalPosts={totalPosts}
          page={page}
          data={data}
          setCount={setCount}
          setPage={setPage}
          isLoading={isLoading}
          landscape={landscape}
          sz={sz}
          scrolled={scrolled}
        />
      )}
      {/* for large devices */}
      {(sz === "lg" || sz === "xl") && (
        <Large
          perPage={perPage}
          totalPosts={totalPosts}
          page={page}
          data={data}
          setCount={setCount}
          setPage={setPage}
          isLoading={isLoading}
          landscape={landscape}
          sz={sz}
          scrolled={scrolled}
        />
      )}
    </>
  );
};

export default React.memo(BlogFold);
