import * as React from "react";
import {
  Center,
  VStack,
  Stack,
  HStack,
  Text,
  Pressable,
  Divider,
  Link,
} from "native-base";

import { vw, vh } from "../../../util";
import Youtube from "../../../assets/svg/Youtube";
import Twitter from "../../../assets/svg/Twitter";
import Facebook from "../../../assets/svg/Facebook";
import FacebookOnHover from "../../../assets/svg/FacebookOnHover";
import TwitterOnHover from "../../../assets/svg/TwitterOnHover";
import YouTubeOnHover from "../../../assets/svg/YouTubeOnHover";
import Instagram from "../../../assets/Instagram";
import Linkdin from "../../../assets/Linkdin";

const BottomBarSmall = (props) => {
  return (
    <>
      <VStack
        bg={"black"}
        h={props.Orientation ? vh(1500) : vh(1000)}
        width={"full"}
      >
        <Center>
          <Center mt={props.Orientation ? vh(200) : vh(80)}>
            <Text
              color={"white"}
              fontSize={props.Orientation ? vh(45) : vw(65)}
              fontFamily={"Poppins"}
            >
              Contact the publishers
            </Text>

            <Link href="mailto:info@propflip.io">
              <Pressable>
                {({ isHovered, isFocused }) => {
                  return (
                    <Stack mt={props.Orientation ? vh(40) : vh(22)}>
                      {isFocused ? (
                        <Text
                          fontSize={props.Orientation ? vh(40) : vw(50)}
                          fontFamily={"Poppins"}
                          color={"darkBlue.600"}
                        >
                          info@propflip.io
                        </Text>
                      ) : (
                        <Text
                          fontSize={props.Orientation ? vh(40) : vw(50)}
                          fontFamily={"Poppins"}
                          color={"white"}
                        >
                          info@propflip.io
                        </Text>
                      )}
                    </Stack>
                  );
                }}
              </Pressable>
            </Link>
          </Center>
          <Divider mt={props.Orientation ? vh(140) : vh(65)} w={vw(1300)} />
          {props.Orientation ? (
            <VStack mt={vh(20)}>
              <HStack space={vw(140)}>
                <VStack>
                  <Center>
                    <Text
                      mt={vh(40)}
                      fontSize={vh(45)}
                      fontFamily={"Poppins"}
                      color={"white"}
                      mb={vh(30)}
                    >
                      Explorate
                    </Text>

                    <Pressable>
                      {({ isHovered, isFocused }) => {
                        return (
                          <Link href="https://propflip.io/#main">
                            <Stack>
                              {isHovered ? (
                                <Text
                                  mt={vh(30)}
                                  fontSize={vh(40)}
                                  fontFamily={"Poppins"}
                                  color={"darkBlue.600"}
                                >
                                  Home
                                </Text>
                              ) : (
                                <Text
                                  mt={vh(30)}
                                  fontSize={vh(40)}
                                  fontFamily={"Poppins"}
                                  color={"white"}
                                >
                                  Home
                                </Text>
                              )}
                            </Stack>
                          </Link>
                        );
                      }}
                    </Pressable>
                    <Pressable>
                      {({ isHovered, isFocused }) => {
                        return (
                          <Link href="https://propflip.io/#howitworks">
                            <Stack>
                              {isHovered ? (
                                <Text
                                  mt={vh(10)}
                                  fontSize={vh(40)}
                                  fontFamily={"Poppins"}
                                  color={"darkBlue.600"}
                                >
                                  How It Works
                                </Text>
                              ) : (
                                <Text
                                  mt={vh(10)}
                                  fontSize={vh(40)}
                                  fontFamily={"Poppins"}
                                  color={"white"}
                                >
                                  How It Works
                                </Text>
                              )}
                            </Stack>
                          </Link>
                        );
                      }}
                    </Pressable>
                    <Pressable>
                      {({ isHovered, isFocused }) => {
                        return (
                          <Link href="https://propflip.io/#features">
                            <Stack>
                              {isHovered ? (
                                <Text
                                  mt={vh(10)}
                                  fontSize={vh(40)}
                                  fontFamily={"Poppins"}
                                  color={"darkBlue.600"}
                                >
                                  Features
                                </Text>
                              ) : (
                                <Text
                                  mt={vh(10)}
                                  fontSize={vh(40)}
                                  fontFamily={"Poppins"}
                                  color={"white"}
                                >
                                  Features
                                </Text>
                              )}
                            </Stack>
                          </Link>
                        );
                      }}
                    </Pressable>
                    {/* <Pressable
              onPress={() => {
                console.log("entred");
              }}
            >
              {({ isHovered, isFocused }) => {
                return (
                  <Link href="https://propflip.io/#features">
                    <Stack>
                      {isHovered ? (
                        <Text
                          mt={vh(10)}
                          fontSize={vh(40)}
                          fontFamily={"Poppins"}
                          color={"darkBlue.600"}
                        >
                          Tutorials
                        </Text>
                      ) : (
                        <Text
                          mt={vh(10)}
                          fontSize={vh(40)}
                          fontFamily={"Poppins"}
                          color={"white"}
                        >
                          Tutorials
                        </Text>
                      )}
                    </Stack>
                  </Link>
                );
              }}
            </Pressable> */}
                    <Pressable>
                      {({ isHovered, isFocused }) => {
                        return (
                          <Link href="https://propflip.io/#pricing">
                            <Stack>
                              {isHovered ? (
                                <Text
                                  mt={vh(10)}
                                  fontSize={vh(40)}
                                  fontFamily={"Poppins"}
                                  color={"darkBlue.600"}
                                >
                                  Pricing
                                </Text>
                              ) : (
                                <Text
                                  mt={vh(10)}
                                  fontSize={vh(40)}
                                  fontFamily={"Poppins"}
                                  color={"white"}
                                >
                                  Pricing
                                </Text>
                              )}
                            </Stack>
                          </Link>
                        );
                      }}
                    </Pressable>
                    <Pressable>
                      {({ isHovered, isFocused }) => {
                        return (
                          <Link href="https://propflip.io/#pricing">
                            <Stack>
                              {isHovered ? (
                                <Text
                                  mt={vh(10)}
                                  fontSize={vh(40)}
                                  fontFamily={"Poppins"}
                                  color={"darkBlue.600"}
                                >
                                  Contact us
                                </Text>
                              ) : (
                                <Text
                                  mt={vh(10)}
                                  fontSize={vh(40)}
                                  fontFamily={"Poppins"}
                                  color={"white"}
                                >
                                  Contact us
                                </Text>
                              )}
                            </Stack>
                          </Link>
                        );
                      }}
                    </Pressable>
                  </Center>
                </VStack>
                <VStack>
                  <Center mt={vh(40)}>
                    <Text
                      fontSize={vh(45)}
                      fontFamily={"Poppins"}
                      color={"white"}
                      mb={vh(30)}
                    >
                      Headquarter
                    </Text>
                    <Text
                      mt={vh(20)}
                      fontSize={vh(40)}
                      fontFamily={"Poppins"}
                      color={"white"}
                    >
                      propflip
                    </Text>
                    <Text
                      mt={vh(20)}
                      lineHeight={vh(40)}
                      fontSize={vh(40)}
                      fontFamily={"Poppins"}
                      color={"white"}
                    >
                      16192 Coastal Highway, Lewes
                    </Text>
                    <Text
                      mt={vh(20)}
                      lineHeight={vh(40)}
                      fontSize={vh(40)}
                      fontFamily={"Poppins"}
                      color={"white"}
                    >
                      Delaware 19958
                    </Text>
                  </Center>
                </VStack>
                <VStack>
                  <Center mt={vh(40)}>
                    <Text
                      fontSize={vh(45)}
                      fontFamily={"Poppins"}
                      color={"white"}
                      mb={vh(50)}
                    >
                      Connections
                    </Text>
                    <HStack mt={vh(30)} alignItems={"center"}>
                      <Link
                        href="https://www.facebook.com/people/Shadow-Properties/100087078565348/"
                        isExternal
                      >
                        <Pressable mr={vw(20)}>
                          {({ isHovered, isFocused }) => {
                            return (
                              <Stack>
                                {isHovered ? (
                                  <FacebookOnHover
                                    height={vh(40)}
                                    width={vw(40)}
                                  />
                                ) : (
                                  <Facebook height={vh(60)} width={vw(60)} />
                                )}
                              </Stack>
                            );
                          }}
                        </Pressable>
                      </Link>

                      <Link href="https://twitter.com/Shadow_CRM" isExternal>
                        <Pressable mr={vw(20)}>
                          {({ isHovered, isFocused }) => {
                            return (
                              <Stack>
                                {isHovered ? (
                                  <TwitterOnHover
                                    height={vh(40)}
                                    width={vw(40)}
                                  />
                                ) : (
                                  <Twitter height={vh(40)} width={vw(40)} />
                                )}
                              </Stack>
                            );
                          }}
                        </Pressable>
                      </Link>

                      <Link href="https://www.youtube.com/@PropFlip" isExternal>
                        <Pressable mr={vw(20)}>
                          {({ isHovered, isFocused }) => {
                            return (
                              <Stack>
                                {isHovered ? (
                                  <YouTubeOnHover
                                    height={vh(40)}
                                    width={vw(40)}
                                  />
                                ) : (
                                  <Youtube height={vh(40)} width={vw(40)} />
                                )}
                              </Stack>
                            );
                          }}
                        </Pressable>
                      </Link>

                      <Link
                        href="https://www.instagram.com/PropFlip_official/"
                        isExternal
                      >
                        <Pressable mr={vw(20)}>
                          {({ isHovered, isFocused }) => {
                            return (
                              <Stack>
                                {isHovered ? (
                                  <Instagram
                                    height={vh(55)}
                                    width={vw(55)}
                                    fill={"#1e88e5"}
                                  />
                                ) : (
                                  <Instagram
                                    height={vh(55)}
                                    width={vw(55)}
                                    fill={"white"}
                                  />
                                )}
                              </Stack>
                            );
                          }}
                        </Pressable>
                      </Link>
                      <Link
                        href="https://www.linkedin.com/company/37566268/admin/"
                        isExternal
                      >
                        <Pressable mr={vw(20)}>
                          {({ isHovered, isFocused }) => {
                            return (
                              <Stack>
                                {isHovered ? (
                                  <Linkdin
                                    height={vh(55)}
                                    width={vw(55)}
                                    fill={"#1e88e5"}
                                  />
                                ) : (
                                  <Linkdin
                                    height={vh(55)}
                                    width={vw(55)}
                                    fill={"white"}
                                  />
                                )}
                              </Stack>
                            );
                          }}
                        </Pressable>
                      </Link>
                    </HStack>
                  </Center>
                </VStack>
              </HStack>
            </VStack>
          ) : (
            <Center>
              <Center>
                <Text
                  mt={vh(40)}
                  fontSize={vw(65)}
                  fontFamily={"Poppins"}
                  color={"white"}
                >
                  Explorate
                </Text>

                <Pressable>
                  {({ isHovered, isFocused }) => {
                    return (
                      <Link href="https://propflip.io/#main">
                        <Stack>
                          {isHovered ? (
                            <Text
                              mt={vh(30)}
                              fontSize={vw(55)}
                              fontFamily={"Poppins"}
                              color={"darkBlue.600"}
                            >
                              Home
                            </Text>
                          ) : (
                            <Text
                              mt={vh(30)}
                              fontSize={vw(55)}
                              fontFamily={"Poppins"}
                              color={"white"}
                            >
                              Home
                            </Text>
                          )}
                        </Stack>
                      </Link>
                    );
                  }}
                </Pressable>
                <Pressable>
                  {({ isHovered, isFocused }) => {
                    return (
                      <Link href="https://propflip.io/#howitworks">
                        <Stack>
                          {isHovered ? (
                            <Text
                              mt={vh(10)}
                              fontSize={vw(55)}
                              fontFamily={"Poppins"}
                              color={"darkBlue.600"}
                            >
                              How It Works
                            </Text>
                          ) : (
                            <Text
                              mt={vh(10)}
                              fontSize={vw(55)}
                              fontFamily={"Poppins"}
                              color={"white"}
                            >
                              How It Works
                            </Text>
                          )}
                        </Stack>
                      </Link>
                    );
                  }}
                </Pressable>
                <Pressable>
                  {({ isHovered, isFocused }) => {
                    return (
                      <Link href="https://propflip.io/#features">
                        <Stack>
                          {isHovered ? (
                            <Text
                              mt={vh(10)}
                              fontSize={vw(55)}
                              fontFamily={"Poppins"}
                              color={"darkBlue.600"}
                            >
                              Features
                            </Text>
                          ) : (
                            <Text
                              mt={vh(10)}
                              fontSize={vw(55)}
                              fontFamily={"Poppins"}
                              color={"white"}
                            >
                              Features
                            </Text>
                          )}
                        </Stack>
                      </Link>
                    );
                  }}
                </Pressable>
                {/* <Pressable
              onPress={() => {
                console.log("entred");
              }}
            >
              {({ isHovered, isFocused }) => {
                return (
                  <Link href="https://propflip.io/#features">
                    <Stack>
                      {isHovered ? (
                        <Text
                          mt={vh(10)}
                          fontSize={vw(55)}
                          fontFamily={"Poppins"}
                          color={"darkBlue.600"}
                        >
                          Tutorials
                        </Text>
                      ) : (
                        <Text
                          mt={vh(10)}
                          fontSize={vw(55)}
                          fontFamily={"Poppins"}
                          color={"white"}
                        >
                          Tutorials
                        </Text>
                      )}
                    </Stack>
                  </Link>
                );
              }}
            </Pressable> */}
                <Pressable>
                  {({ isHovered, isFocused }) => {
                    return (
                      <Link href="https://propflip.io/#pricing">
                        <Stack>
                          {isHovered ? (
                            <Text
                              mt={vh(10)}
                              fontSize={vw(55)}
                              fontFamily={"Poppins"}
                              color={"darkBlue.600"}
                            >
                              Pricing
                            </Text>
                          ) : (
                            <Text
                              mt={vh(10)}
                              fontSize={vw(55)}
                              fontFamily={"Poppins"}
                              color={"white"}
                            >
                              Pricing
                            </Text>
                          )}
                        </Stack>
                      </Link>
                    );
                  }}
                </Pressable>
                <Pressable>
                  {({ isHovered, isFocused }) => {
                    return (
                      <Link href="https://propflip.io/#pricing">
                        <Stack>
                          {isHovered ? (
                            <Text
                              mt={vh(10)}
                              fontSize={vw(55)}
                              fontFamily={"Poppins"}
                              color={"darkBlue.600"}
                            >
                              Contact us
                            </Text>
                          ) : (
                            <Text
                              mt={vh(10)}
                              fontSize={vw(55)}
                              fontFamily={"Poppins"}
                              color={"white"}
                            >
                              Contact us
                            </Text>
                          )}
                        </Stack>
                      </Link>
                    );
                  }}
                </Pressable>
              </Center>
              <Center mt={vh(40)}>
                <Text fontSize={vw(65)} fontFamily={"Poppins"} color={"white"}>
                  Headquarter
                </Text>
                <Text
                  mt={vh(20)}
                  fontSize={vw(55)}
                  fontFamily={"Poppins"}
                  color={"white"}
                >
                  propflip
                </Text>
                <Text
                  lineHeight={vh(40)}
                  fontSize={vw(55)}
                  fontFamily={"Poppins"}
                  color={"white"}
                >
                  16192 Coastal Highway, Lewes
                </Text>
                <Text
                  lineHeight={vh(40)}
                  fontSize={vw(55)}
                  fontFamily={"Poppins"}
                  color={"white"}
                >
                  Delaware 19958
                </Text>
              </Center>
              <Center mt={vh(40)}>
                <Text fontSize={vw(65)} fontFamily={"Poppins"} color={"white"}>
                  Connections
                </Text>
                <HStack mt={vh(30)} alignItems={"center"} space={vw(90)}>
                  <Link
                    href="https://www.facebook.com/people/Shadow-Properties/100087078565348/"
                    isExternal
                  >
                    <Pressable mr={vw(20)}>
                      {({ isHovered, isFocused }) => {
                        return (
                          <Stack>
                            {isHovered ? (
                              <FacebookOnHover height={vh(60)} width={vw(60)} />
                            ) : (
                              <Facebook height={vh(60)} width={vw(60)} />
                            )}
                          </Stack>
                        );
                      }}
                    </Pressable>
                  </Link>

                  <Link href="https://twitter.com/Shadow_CRM" isExternal>
                    <Pressable mr={vw(20)}>
                      {({ isHovered, isFocused }) => {
                        return (
                          <Stack>
                            {isHovered ? (
                              <TwitterOnHover height={vh(60)} width={vw(60)} />
                            ) : (
                              <Twitter height={vh(60)} width={vw(60)} />
                            )}
                          </Stack>
                        );
                      }}
                    </Pressable>
                  </Link>

                  <Link href="https://www.youtube.com/@PropFlip" isExternal>
                    <Pressable mr={vw(20)}>
                      {({ isHovered, isFocused }) => {
                        return (
                          <Stack>
                            {isHovered ? (
                              <YouTubeOnHover height={vh(60)} width={vw(60)} />
                            ) : (
                              <Youtube height={vh(60)} width={vw(60)} />
                            )}
                          </Stack>
                        );
                      }}
                    </Pressable>
                  </Link>

                  <Link
                    href="https://www.instagram.com/PropFlip_official/"
                    isExternal
                  >
                    <Pressable mr={vw(20)}>
                      {({ isHovered, isFocused }) => {
                        return (
                          <Stack>
                            {isHovered ? (
                              <Instagram
                                height={vh(75)}
                                width={vw(75)}
                                fill={"#1e88e5"}
                              />
                            ) : (
                              <Instagram
                                height={vh(75)}
                                width={vw(75)}
                                fill={"white"}
                              />
                            )}
                          </Stack>
                        );
                      }}
                    </Pressable>
                  </Link>
                  <Link
                    href="https://www.linkedin.com/company/37566268/admin/"
                    isExternal
                  >
                    <Pressable mr={vw(20)}>
                      {({ isHovered, isFocused }) => {
                        return (
                          <Stack>
                            {isHovered ? (
                              <Linkdin
                                height={vh(75)}
                                width={vw(75)}
                                fill={"#1e88e5"}
                              />
                            ) : (
                              <Linkdin
                                height={vh(75)}
                                width={vw(75)}
                                fill={"white"}
                              />
                            )}
                          </Stack>
                        );
                      }}
                    </Pressable>
                  </Link>
                </HStack>
              </Center>
            </Center>
          )}
        </Center>
      </VStack>
    </>
  );
};

export default React.memo(BottomBarSmall);
