import * as React from "react";
import { vw, vh } from "../../util";

const WhiteLogo = (props) => (
  <svg
    // width={29}
    // height={36}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 72 88"
    style={{
      enableBackground: "new 0 0 72 88",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M48.6,10.1l-4.8-3.8L36,0L0,28.8v24.4v2.3v3.7h15.6v15.3h6V59.2h24.5c5.9,0,10.9-3.9,12.6-9.3 c0.5-1.3,0.7-2.5,0.7-3.9c0-3.7-1.5-7-4-9.3c-2.3-2.4-5.7-3.9-9.3-3.9H15.6v14.4h6v-8.4h24.5c2,0,3.8,0.8,5.1,2.1 c1.3,1.3,2.2,3.1,2.2,5.1c0,2-0.8,3.8-2.2,5.1c-1.3,1.3-3.1,2.1-5.1,2.1H6V31.7l30-24l3,2.4l4.8,3.8L66,31.7V82H0v6h72V28.8 L48.6,10.1z" fill="#fff" />
  </svg>
);
export default WhiteLogo;
