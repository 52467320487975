import React from "react";
import {
  VStack,
  HStack,
  Text,
  Pressable,
  Link as NLink,
  Center,
} from "native-base";

import { vw, vh } from "../../util";
import "./one.css";

//fold one small
const Small = ({ landscape, sendIp }) => {
  let platform = "web";
  if (navigator.userAgent.includes("iPhone")) {
    platform = "ios";
  } else if (navigator.userAgent.includes("Android")) {
    platform = "android";
  }
  const bg = landscape
    ? platform === "android"
      ? "https://cdn.propflip.io/propflip.io/hero/Web.webm"
      : "https://cdn.propflip.io/propflip.io/hero/Web.mp4"
    : platform === "android"
    ? "https://cdn.shadow.properties/shadow.properties/hero/mobile.webm"
    : "https://cdn.shadow.properties/shadow.properties/hero/mobile.mp4";
  // const [isNavExpanded, setIsNavExpanded] = useState(false);
  // const [login, onlogin] = useState(false);
  //console.log("Fold 1 small Landscape", landscape);
  // const styles = {
  //   container: {
  //     height: "32px",
  //     width: "32px",
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "center",
  //     alignItems: "flex-end",
  //     padding: "4px",
  //     marginRight: "20px",
  //   },
  //   line: {
  //     height: "3px",
  //     width: "22px",
  //     background: "white",
  //     borderRadius: "20px",
  //     transition: "all 0.2s ease",
  //   },
  //   lineTop: {
  //     transform: isNavExpanded ? "translateX(-2px) rotate(-45deg)" : "none",
  //     transformOrigin: "top right",
  //     marginBottom: "5px",
  //   },
  //   lineMiddle: {
  //     opacity: isNavExpanded ? 0 : 1,
  //     transform: isNavExpanded ? "translateX(16px)" : "none",
  //     width: "14px",
  //   },
  //   lineBottom: {
  //     transform: isNavExpanded ? "rotate(45deg)" : "none",
  //     transformOrigin: "top right",
  //     marginTop: "5px",
  //   },
  // };
  return (
    <VStack
      style={{
        height: window.innerHeight,
        zIndex: 9,
      }}
    >
      <video
        src={bg}
        poster={
          landscape
            ? "https://cdn.propflip.io/propflip.io.png/first-bg.webp"
            : "https://cdn.propflip.io/propflip.io.png/hero-image-mobile.webp"
        }
        loop
        autoPlay
        playsInline
        muted
        id="myVideo"
      >
        <source src={bg} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <VStack style={{ position: "absolute", width: "100%", height: "100%" }}>
        {/* <HStack
          space={2}
          style={{
            zIndex: 99,
            justifyContent: "space-between",
            alignItems: "center",
            paddingHorizontal: vw(25),
            paddingTop: vh(15),
          }}
        >
          <Center style={{ margin: vh(20) }}>
            <WhiteLogo
              width={landscape ? 68 : 80}
              height={landscape ? 40 : 60}
            />
          </Center>

          <div
            style={styles.container}
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            <div style={{ ...styles.line, ...styles.lineTop }} />
            <div style={{ ...styles.line, ...styles.lineMiddle }} />
            <div style={{ ...styles.line, ...styles.lineBottom }} />
          </div>
        </HStack> */}
        {/* <VStack
          style={{
            // display: isOpen ? "flex" : "none",
            position: "absolute",
            zIndex: 99,
            width: "100%",
            height: "min-content",
            top: "12vh",
            justifyContent: "center",
            alignItems: "center",
            // paddingTop: vw(300),
            // backgroundColor: "white",
            transform: isNavExpanded ? "translateY(0%)" : "translateY(-200%)",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <VStack
            justifyContent={"center"}
            alignItems={"center"}
            w={"90%"}
            // h={isNavExpanded ? "110%" : "100%"}
            backgroundColor={"white"}
            transition="all 0.3s linear"
            style={{
              borderRadius: "3vh",
              backgroundColor: "#5b32b4",
              boxShadow: "5px 10px 25px #5b32b4",
              color: "white",
              padding: "10%",
              opacity: isNavExpanded ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
            }}
          >
            <ul style={{ marginLeft: -20 }}>
              <li style={{ listStyleType: "none", margin: 5 }}>
                <NLink
                  href="https://calendly.com/PropFlip/30min"
                  isUnderlined={false}
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  <Text
                    fontSize={16.5}
                    fontFamily={"poppins"}
                    color="white"
                  >
                    Demo
                  </Text>
                </NLink>
              </li>
              <li style={{ listStyleType: "none", margin: 5 }}>
                <Link
                  to={"fold2"}
                  smooth={true}
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  How it works
                </Link>
              </li>
              <li style={{ listStyleType: "none", margin: 5 }}>
                <Link
                  to={"fold4"}
                  smooth={true}
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  Features
                </Link>
              </li>
              <li style={{ listStyleType: "none", margin: 5 }}>
                <Link
                  to={"fold5"}
                  smooth={true}
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  Pricing
                </Link>
              </li>
              <li style={{ listStyleType: "none", margin: 5 }}>
                <Link
                  to={"fold7"}
                  smooth={true}
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                  color="white"
                >
                  Contact
                </Link>
              </li>
              <li style={{ listStyleType: "none", margin: 5 }}>
                <Pressable
                  onPressIn={() => {
                    onlogin(true);
                  }}
                  onPressOut={() => {
                    onlogin(false);
                  }}
                >
                  <NLink
                    href="/blog"
                    isUnderlined={false}
                    onClick={() => {
                      setIsNavExpanded(!isNavExpanded);
                    }}
                  >
                    <Text
                      fontSize={16.5}
                      fontFamily={"poppins"}
                      color="white"
                    >
                      Blog
                    </Text>
                  </NLink>
                </Pressable>
              </li>
              <li style={{ listStyleType: "none", margin: 5 }}>
                <Pressable
                  onPressIn={() => {
                    onlogin(true);
                  }}
                  onPressOut={() => {
                    onlogin(false);
                  }}
                >
                  <NLink
                    href="https://web.shadow.properties"
                    isExternal
                    isUnderlined={false}
                    onClick={() => {
                      setIsNavExpanded(!isNavExpanded);
                    }}
                  >
                    <Text
                      fontSize={16.5}
                      fontFamily={"poppins"}
                      color="white"
                    >
                      Signup / Login
                    </Text>
                  </NLink>
                </Pressable>
              </li>
            </ul>
          </VStack>
        </VStack> */}
        <Center>
          <Text
            style={{
              marginTop: vh(250),
              height: vh(200),
              lineHeight: landscape ? vh(110) : vw(170),
              color: "white",
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: vw(landscape ? 65 : 130),
              textAlign: landscape ? "left" : "left",
              // textShadowColor: "black",
              // textShadowRadius: 1,
              marginLeft: vw(landscape ? 100 : false),
              justifyContent: "center",
              alignItems: "center",
              width: "90%",
            }}
          >
            Establish, Manage, and Grow as a Realtor
          </Text>
        </Center>

        <HStack
          style={{
            // backgroundColor: "white",
            position: "absolute",
            // marginTop: landscape ? vh(200) : "auto",
            // marginBottom: 80,
            justifyContent: landscape ? "flex-start" : "center",
            alignItems: "center",
            alignContent: "flex-end",
            // height: vh(50),
            marginHorizontal: vw(landscape ? 90 : 80),
            bottom: "3vh",
          }}
        >
          <Pressable
            style={{
              // borderRadius: 25,
              borderWidth: 2,
              borderColor: "white",
              width: vw(landscape ? 400 : 850),
              height: vh(landscape ? 90 : 80),
              // alignItems: "center",
              justifyContent: "center",
              // boxShadow: "0px 1px 1px -1px",
            }}
            onPress={sendIp}
            rounded={"full"}
          >
            <Text
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                color: "white",
                fontSize: vw(landscape ? 28 : 72),
                textAlign: "center",
                paddingTop: vh(4.5),
                // textShadowColor: "black",
                // textShadowRadius: 3,
              }}
            >
              Start Free Trial
            </Text>
          </Pressable>
          {/* <Circle
          _text={{
            fontSize: vw(30),
            color: "#7D2BE9",
          }}
          style={{
            backgroundColor: "rgba(255,255,255, 0.2)",
            marginLeft: vw(60),
            width: vw(landscape ? 90 : 160),
            height: vw(landscape ? 90 : 160),
          }}
        >
          <Circle
            style={{
              backgroundColor: "white",
              width: vw(landscape ? 70 : 140),
              height: vw(landscape ? 70 : 140),
            }}
          >
            <PlayIcon size={vw(landscape ? 40 : 80)} color={"#7D2BE9"} />
          </Circle>
        </Circle>
        <Pressable
          style={{
            height: vh(60),
            marginLeft: vw(landscape ? 10 : 20),
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "HelveticaNeueLTStd-Md",
              color: "white",
              fontSize: vw(landscape ? 28 : 36),
              textAlign: "center",
            }}
          >
            Watch Video
          </Text>
        </Pressable> */}
        </HStack>
      </VStack>
    </VStack>
  );
};

export default React.memo(Small);
